import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import TeamDetailInfo from "../components/team-detail-info/team-detail-info"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import Layout from "../components/layout"
import HomeBanner from "../components/home-banner/home-banner"
import logoBlack from "../images/svg/logo.svg"
import NotFoundPage from "../pages/404"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { useStaticQuery, graphql } from "gatsby"
import TeamBanner from "../components/home-banner/team-banner"
import OtherNewsSales from "../components/news-sales/other-news-sales"
const TeamDetail = (props) => {
  const banner = useStaticQuery(graphql`
  query {
    glstrapi {
      contact {
        Team_Banner_Cta{
            Big_Title
            Content
            Publish
            Banner_Cta {
              Label
              Link
              Url {
                Alias
              }
            }
            Image {
              alternativeText
              url
            }
            Title
          }
      }
    }
  }
  `)

  const GET_TEAM_DETAILS = gql`
  query GetTeam($URL: String!) {
    teams(where: { URL: $URL }) {
      id
      imagetransforms
      Name
      Email
      Designation
      Phone
      Video_Url
      URL
      Image {
        alternativeText
        url
      }
      Content
      Video_Url
      offices {
        Choose_Team_Members {
          id
            imagetransforms
            Name
            Email
            Designation
            Video_Url
            URL
            Image {
              alternativeText
              url
            }
          }
        }
      
     
    }
  }
`
  const { loading, error, data } = useQuery(GET_TEAM_DETAILS, {
    variables: { URL: props.slug },
    fetchPolicy: "no-cache"
  })
  let shareurl = ""
  if (typeof window !== "undefined") {
    shareurl = window.location.href ? window.location.href : ""
  }
  // const teamData = data?.teams?.offices && data?.teams?.offices[0]?.Choose_Team_Members?.filter((o, i) => o.Name !== data?.teams?.Name)
  // console.log("text", teamData)
  if (loading)
  return (
    <div className="static-news gql-loading-screen">
      <Container>
        <div className="gql-loading">
          <img
            className="logo-white loader-logo"
            src={logoBlack}
            alt="logo"
          />
        </div>
      </Container>
    </div>
  )

  return data?.teams?.length > 0 ? (
    <Layout
    >
      {data?.teams?.map((Page, i) => {
        let processedImages = JSON.stringify({})
        if (Page?.imagetransforms?.Image_Transforms) {
          processedImages = Page.imagetransforms.Image_Transforms
        }
        const path = {
          pathname: `/about/${props?.alias}/${props?.slug}/`
        }
        return (
          <>
            <Seo
              title={
                Page ? Page?.Name + " | " + Page?.Designation : ""
              }
              location={path}
              description={
                Page
                  ? `Get to know about ${Page?.Name} here. Contact one of estate agents for assistance in finding the right properties for you.`
                  : ""
              }
              image={Page?.Image?.url ? Page.Image.url : null}
            />
            <Helmet
              bodyAttributes={{
                class: `templates-blog-details-template team-page`,
              }}
            />

            <Breadcrumbs Page={Page?.Name} alias={"meet-our-team"} type={"details-page"} />
            <div className="bg-linear">
              <TeamDetailInfo data={Page} />
              </div>
              {/* {JSON.stringify(Page?.offices[0]?.Choose_Team_Members)} */}
            <OtherNewsSales URL={Page?.URL} 
            teamData={Page?.offices && Page?.offices[0]?.Choose_Team_Members?.filter((o, i) => o?.Name !== Page?.Name)} />
                  <TeamBanner
                  Banner_block={banner?.glstrapi?.contact?.Team_Banner_Cta}
/>
                    
          </>
        )
      })}
    </Layout>
  ) : (
    <Layout className="blog-page">
      <Router>
        <Switch>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Layout>
  )

}

export default TeamDetail

